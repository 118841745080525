import actionTypes from "../actions/actionTypes";

const initialState = {
  isLoggedIn: false,
  userInfor: null,
};

const appReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.USER_LOGIN_SUCCESS:
      return {
        ...state,
        isLoggedIn: true,
        userInfor: action.userInfor,
      };
    case actionTypes.USER_LOGIN_F:
      return {
        ...state,
        isLoggedIn: false,
        userInfor: null,
      };
    case actionTypes.PROCESS_LOGOUT:
      return {
        ...state,
        isLoggedIn: false,
        userInfor: null,
      };
    default:
      return state;
  }
};

export default appReducer;
